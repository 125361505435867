import React from "react";
import cn from "classnames";
import styles from "./Steps.module.sass";
import ScrollParallax from "../../../components/ScrollParallax";

const items = [
  {
    title: "Begin your journey",
    color: "#3772FF",
    images: "/images/content/download.svg",
    content:
      "Converse with Harmony, our AI assistant, who gets to know you, your goals, and your preferences.",
  },
  {
    title: "Match with coaches",
    color: "#9757D7",
    images: "/images/content/whistle.svg",
    content:
      "Based on your profile, Harmony will match you to coaches that align with your needs for each co-pilot.", // Whether you're looking to run a faster 40yd dash, recover from an injury, or simply improve your overall fitness, we have a coach for you.",
  },
  {
    title: "Get personalized plans",
    color: "#EF466F",
    images: "/images/content/stopwatch.svg",
    content:
      "Harmony collaborates with your coaches to create wellness plans tailored to your needs.",
  },
  {
    title: "Continue your journey",
    color: "#45B26B",
    images: "/images/content/medal.svg",
    content:
      "Engage in focused conversations, explore new wellness topics, track your plans and progress, and more!",
  },
];

const Steps = ({ scrollToRef }) => {
  return (
    <div className={cn("section", styles.section)} ref={scrollToRef}>
        <div className={styles.head}>
          <h2 className={cn("h2", styles.title)}>How it works</h2>
          <div className={styles.info}>
          Harmony, by Feel 
            Good AI, is a production-ready platform of stackable wellness modules. Each 'co-pilot' focuses on a single apsect of wellness.
          </div>
        </div>
        <div className={styles.list}>
          {items.map((x, index) => (
            <ScrollParallax className={styles.item} key={index}>
              <div
                className={styles.preview}
                style={{ backgroundColor: x.color }}
              >
                <img src={x.images} alt={`Step ${index}`} />
              </div>
              <div className={styles.number}>Step {index + 1}</div>
              <div className={styles.subtitle}>{x.title}</div>
              <div className={styles.content}>{x.content}</div>
            </ScrollParallax>
          ))}
        </div>
    </div>
  );
};

export default Steps;
