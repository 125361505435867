import React, {useState, useEffect, useRef} from "react";
import styles from "./Chat.module.sass";
import Image from "../../components/Image";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {getAuth, signOut} from "firebase/auth";
import {
    collection,
    getFirestore,
    getDoc,
    doc,
    getDocs,
} from "firebase/firestore";

const MessageBubble = ({variant, message, loading}) => {
    return (
        <div className={styles.text}>
            {variant === "ai" && (
                <div>
                    <Image
                        className={styles.avatar}
                        src="../../a512.png"
                        srcDark="../../a512.png"
                        alt="Feel Good AI"
                    ></Image>
                </div>
            )}
            <div className={styles.messageBubbleContainer}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: variant === "ai" ? "flex-start" : "flex-end",
                        width: "100%",
                    }}
                >
                    <div className={styles.messageBubble}>
                        <p>{message}</p>
                    </div>
                </div>
            </div>
            {variant === "human" && (
                <div>
                    <Image
                        className={styles.avatar}
                        src="../../user.png"
                        srcDark="../../user.png"
                        alt="Profile"
                    ></Image>
                </div>
            )}
        </div>
    );
};

const initialText = {
    athletes_1:
        "Hello world...jk, we're here to chat about Identity and Performance Optimization for Active Athletes",
    athletes_2:
        "Hello world...jk, we're here to chat about Injury Recovery and Identity Support for Athletes",
    athletes_3:
        "Hello world...jk, we're here to chat about Transitioning Out of Sports - A Comprehensive Guide for Athletes",
    performers_1:
        "Hello world...jk, we're here to chat about Aligning Goals, Discovering Purpose, and Understanding Identity",
    performers_2:
        "Hello world...jk, we're here to chat about Applying Wisdom, Enduring Suffering, and Embracing Legacy",
    performers_3:
        "Hello world...jk, we're here to chat about Cultivating Mindsets and Leadership Excellence",
    harmony:
        "When athletes transition out of sport they often face a significant sense of loss, grief, and other negative emotions. This shift can result in sadness, restlessness, and uncertainty about their future without the structure and identity that sports provided.\n" +
        "\n" +
        "These negative psychological responses can feel isolating if athletes think they are the only ones experiencing them. Many athletes believe they're alone in their struggles, but in reality, many face similar challenges when leaving their sport.\n" +
        "\n" +
        "Self-reflection has been shown to not just optimize performance in our daily lives, but also reduce stress, boost well-being, provide space from negative thoughts, and provide a way to process emotions.\n" +
        "\n" +
        "We are constantly evolving. Journaling helps us to listen, bear witness to these changes, and simply get to know ourselves a whole lot better.\n" +
        "\n" +
        "Harmony is your Peak Performance Coach, designed to help you navigate change, self reflect, and deepen your relationship with yourself.\"\n",
};

const Chat = ({app, user, token}) => {
    const ENVIRONMENT = "PROD"; // TODO - replace with intelligent environment switching

    // const { chatId } = useParams();
    const { chatName } = useParams();

    const firestore = getFirestore(app);
    const [savedMessages, setSavedMessages] = useState([]);
    const [loading, setLoading] = useState(true);
    if (user && user.uid && chatName) {
        getDocs(
            collection(firestore, "users", user.uid, "chats", chatName, "messages")
        )
            .then((results) => {
                let messageList = [];
                results.forEach((result) => {
                    const data = result.data();
                    messageList.push({
                        message: data.text,
                        variant: data.isUser ? "human" : "ai",
                        loading: false,
                        timestamp: data.timestamp,
                    });
                });
                messageList = messageList.sort((a, b) =>
                    a.timestamp < b.timestamp ? -1 : 1
                );
                setSavedMessages(messageList);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    }

    const [messageData, setMessageData] = useState([]);
    const [messages, setMessages] = useState(undefined);
    const scrollRef = useRef(null);

    useEffect(() => {
        console.log(chatName + " " + loading + " " + savedMessages.length);
        scrollRef.current.scrollIntoView();
        if (messageData.length > 0) {
            const newMessages = messageData.map(({message, variant, loading}) => {
                return (
                    <MessageBubble
                        message={message}
                        variant={variant}
                        loading={loading}
                    />
                );
            });
            setMessages(newMessages);
        } else if (!loading) {
            if (savedMessages.length === 0) {
                setMessageData([
                    {
                        variant: "ai",
                        message: initialText[chatName],
                        loading: false,
                    },
                ]);
            } else {
                setMessageData(savedMessages);
            }
        }
    }, [messageData, loading]);
    const navigate = useNavigate();
    const auth = getAuth();

    const [text, setText] = useState("");
    const createMessage = () => {
        const newMessageData = [
            ...messageData,
            {message: text, variant: "human", loading: "false"},
            {message: "...", variant: "ai", loading: "true"},
        ];
        setMessageData([
            ...messageData,
            {message: text, variant: "human", loading: "false"},
            {message: "...", variant: "ai", loading: "true"},
        ]);
        setText("");
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authentication: `bearer ${token}`,
            },
            body: JSON.stringify({
                userId: user.uid,
                messageText: text,
                chatName: chatName,
            }),
        };
        fetch(
            ENVIRONMENT === "DEV"
                ? `http://localhost:8010/proxy/queryChat`
                : "https://us-central1-harmony-c543e.cloudfunctions.net/queryChat",
            requestOptions
        )
            .then((response) => {
                return response.text();
            })
            .then((data) => {
                setMessageData([
                    ...newMessageData.slice(0, newMessageData.length - 1),
                    {
                        message: data,
                        variant: "ai",
                        loading: false,
                    },
                ]);
            });
    };
    return (
        <div className={styles.fullPage}>
            <div className={styles.navbar}>
                <div style={{color: '#1c1c1c'}}>
                    {auth?.currentUser?.email}
                    <span style={{marginLeft: `50px`}}/>
                    {/*<NavLink className={styles.link} to="/try-it-out">*/}
                    {/*  Menu*/}
                    {/*</NavLink>*/}
                </div>
                <div>
                    <NavLink
                        className={styles.link}
                        onClick={() => {
                            signOut(auth)
                                .then(() => {
                                    localStorage.setItem("userId", undefined);
                                    navigate("/");
                                })
                                .catch(() => {
                                    localStorage.setItem("userId", undefined);
                                    navigate("/");
                                    console.error("something went wrong");
                                });
                        }}
                    >
                        Log out
                    </NavLink>
                </div>
            </div>
            <div
                className={styles.logoContainer}
            >
                <Image
                    className={styles.logo}
                    src="../../images/logo-dark-cropped.svg"
                    srcDark="../../images/logo-dark-cropped.svg"
                    alt="Feel Good AI"
                />
            </div>
            <div className={styles.rootContainer}>
                <div ref={scrollRef}></div>
                <div className={styles.chatContainer}>{messages}</div>
            </div>
            <div className={styles.inputContainer}>
                <input
                    value={text}
                    placeholder="Compose message..."
                    onKeyDown={(e) => {
                        if (e.code === "Enter") {
                            scrollRef.current?.scrollIntoView({
                                behavior: "smooth",
                                block: "nearest",
                                inline: "center",
                                alignToTop: false,
                            });
                            createMessage();
                        }
                    }}
                    onChange={(e) => {
                        setText(e.target.value);
                    }}
                ></input>
                <button
                    disabled={text.length < 1}
                    style={text.length >= 1 ? null : {color: "#aaaaaa", background: "#cccccc"}}
                    onClick={() => {
                        scrollRef.current?.scrollIntoView({behavior: "smooth"});
                        createMessage();
                    }}
                >
                    Send
                </button>
            </div>
        </div>
    );
};

export default Chat;
