import React, { useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import cn from "classnames";
import styles from "./Header.module.sass";
import DropdownMenu from "./DropdownMenu";
import Icon from "../Icon";
import Image from "../Image";

const socials = [
  {
    title: "twitter",
    size: "18",
    url: "https://twitter.com/feelgoodai",
  },
  {
    title: "instagram",
    size: "16",
    url: "https://www.instagram.com/feelgoodai",
  },
];

const contact = [
  {
    title: "Montanachester",
    content: "06787 Block Estates",
  },
  {
    title: "Lake Gene",
    content: "167 Emard River",
  },
  {
    title: "North Hassiefort",
    content: "032 Leonora Spurs",
  },
];

const Header = ({
  onStepsPress,
  onCopilotsPress,
  onCoachesPress,
  onPartnershipsPress,
  onFaqPress,
  onTryPress,
}) => {
  const navLinks = [
    {
      title: "Co-Pilots",
      callback: onCopilotsPress,
    },
    {
      title: "How it works",
      callback: onStepsPress,
    },
    {
      title: "Coaches",
      callback: onCoachesPress,
    },
    {
      title: "Partnerships",
      callback: onPartnershipsPress,
    },
    {
      title: "FAQ",
      callback: onFaqPress,
    },
    {
      title: "Try it out",
      callback: onTryPress,
    },
  ];

  const [visibleNav, setVisibleNav] = useState(false);
  const { pathname } = useLocation();

  return (
    <header className={styles.header}>
      <div className={cn("container", styles.container)}>
        <Link
          className={styles.logoContainer}
          to="/"
          onClick={() => setVisibleNav(false)}
        >
          <Image
            className={styles.pic}
            src="/images/logo-dark.svg"
            srcDark="/images/logo-light.svg"
            alt="Feel Good AI"
          />
        </Link>
        <div className={cn(styles.wrap, { [styles.active]: visibleNav })}>
          <nav className={styles.nav}>
            {navLinks.map((x, index) =>
              x.content ? (
                <DropdownMenu
                  className={styles.group}
                  item={x.title}
                  key={index}
                  setValue={setVisibleNav}
                />
              ) : (
                <NavLink
                  className={cn(styles.link, {
                    [styles.active]: pathname === x.url,
                  })}
                  // to={x.url}
                  key={index}
                  onClick={x.callback}
                >
                  {x.title}
                </NavLink>
              )
            )}
          </nav>
          <div className={styles.details}>
            <div className={styles.contact}>
              {contact.map((x, index) => (
                <div className={styles.element} key={index}>
                  <div className={styles.category}>{x.title}</div>
                  <div className={styles.text}>{x.content}</div>
                </div>
              ))}
            </div>
            <div className={styles.socials}>
              {socials.map((x, index) => (
                <a
                  className={styles.social}
                  href={x.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={index}
                >
                  <Icon name={x.title} size={x.size} />
                </a>
              ))}
            </div>
          </div>
        </div>
        <button
          className={cn(styles.burger, {
            [styles.active]: visibleNav,
          })}
          onClick={() => setVisibleNav(!visibleNav)}
        ></button>
      </div>
    </header>
  );
};

export default Header;
