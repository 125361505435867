import {useState, useEffect} from "react";
import styles from "./SignUp.module.sass";
import Image from "../../components/Image";
import {useNavigate} from "react-router-dom";
import {
    getAuth,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
} from "firebase/auth";

const SignUp = ({app, user}) => {
    const navigate = useNavigate();

    const auth = getAuth();

    const [userName, setUserName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirmation, setPasswordConfirmation] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [loginState, setLoginState] = useState("register");
    const [disabled, setDisabled] = useState(false);
    const userId = localStorage.getItem("userId");

    useEffect(() => {
        if (user) {
            navigate("/try-it-out");
        }

        // TODO test email validator regex
        setDisabled(
            (loginState === "register" && password !== passwordConfirmation) ||
            (email.length < 4 || password.length < 8 ||
                !/(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/.test(
                    email)
            )
        );
    }, [app, password, passwordConfirmation, email, userName, loginState, user]);

    return (
        <>
            {(!user || userId === undefined) && (
                <div className={styles.dottedBackground}>
                    <div className={styles.logoContainer}>
                        <Image
                            className={styles.logo}
                            src="../../a512.png"
                            srcDark="../../a512.png"
                            alt="Feel Good AI"
                        />
                    </div>
                    <div className={styles.headerContainer}>
                        <div>
                            <h1>Talk to Harmony</h1>
                        </div>
                        <div>
                            <h3>Your AI Wellness Assistant</h3>
                        </div>
                    </div>
                    <div className={styles.flex}>
                        <div className={styles.formContainer}>
                            <form>
                                {loginState === "register" ? (
                                    <>
                                        <input
                                            value={email}
                                            onChange={(e) => {
                                                setEmail(e.target.value);
                                            }}
                                            type="email"
                                            placeholder="Email..."
                                        ></input>
                                        <div className={styles.spacer}/>
                                        <input
                                            value={password}
                                            onChange={(e) => {
                                                setPassword(e.target.value);
                                            }}
                                            type={showPassword ? "text" : "password"}
                                            placeholder="Password..."
                                        ></input>
                                        <div className={styles.spacer}/>
                                        <input
                                            value={passwordConfirmation}
                                            onChange={(e) => {
                                                setPasswordConfirmation(e.target.value);
                                            }}
                                            type={showPassword ? "text" : "password"}
                                            placeholder="Confirm password..."
                                        ></input>
                                    </>
                                ) : (<>
                                        <input
                                            value={email}
                                            onChange={(e) => {
                                                setEmail(e.target.value);
                                            }}
                                            type="email"
                                            placeholder="Email..."
                                        ></input>
                                        <div className={styles.spacer}/>
                                        <input
                                            value={password}
                                            onChange={(e) => {
                                                setPassword(e.target.value);
                                            }}
                                            type={showPassword ? "text" : "password"}
                                            placeholder="Password..."
                                        ></input>
                                    </>

                                )}
                            </form>

                            <button
                                disabled={disabled}
                                style={!disabled ? null : {color: "#aaaaaa", background: "#cccccc"}}
                                onClick={() => {
                                    if (loginState === "register") {
                                        createUserWithEmailAndPassword(auth, email, password)
                                            .then((userCredential) => {
                                                const user = userCredential.user;
                                                navigate("/try-it-out");
                                            })
                                            .catch((error) => {
                                                const errorCode = error.code;
                                                const errorMessage = error.message;
                                                alert(`${errorCode.replace("auth/", "")}`);
                                                console.error(`${errorMessage} ${errorCode}`);
                                            })
                                    } else if (loginState === "login") {
                                                    signInWithEmailAndPassword(auth, email, password)
                                                        .then((userCredential) => {
                                                            // TODO - decide what to use this user instance for
                                                            const user = userCredential.user;
                                                            navigate("/try-it-out");
                                                        })
                                                        .catch((error) => {
                                                            const errorCode = error.code;
                                                            const errorMessage = error.message;
                                                            alert(`${errorCode.replace("auth/",  "")}`);
                                                            console.error(`${errorMessage} ${errorCode}`);
                                                        });
                                    }
                                }}
                            >
                                {loginState === "register" ? "Register" : "Log In"}
                            </button>
                            <div className={styles.spacer}/>

                            <div style={{display: 'flex', justifyContent: 'space-between'}}>

                                <a
                                    href="#"
                                    onClick={(e) => {
                                        if (loginState === "register") {
                                            setLoginState("login");
                                        } else if (loginState === "login") {
                                            setLoginState("register");
                                        }
                                    }}
                                    style={{cursor: "pointer", color: "#1c1c1c"}}  // Make it look like a hyperlink
                                >
                                    {loginState === "register" ? "Already a user?" : "Create account"}
                                </a>
                                <div className={styles.spacer}/>
                                <a
                                    href="#"
                                    onClick={(e) => {
                                        setShowPassword(!showPassword);
                                    }}
                                    style={{cursor: "pointer", color: "#1c1c1c"}}  // Make it look like a hyperlink
                                >
                                    {showPassword ? "Hide password" : "Show password"}
                                </a>
                            </div>

                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default SignUp;
