import React, { useRef } from "react";
import styles from "./Page.module.sass";
import Header from "../Header";
import Footer from "../Footer";
import Hero from "../../screens/Home/Hero";
import Steps from "../../screens/Home/Steps";
import Coaches from "../../screens/Coaches";
import Lifestyle from "../../screens/Lifestyle";
import CoPilots from "../../screens/CoPilots";
import Faq from "../../screens/Pricing/Faq";
import Subscription from "../Subscription";
import { useNavigate } from "react-router-dom";

const Page = () => {
  const stepsScrollRef = useRef(null);
  const copilotsScrollRef = useRef(null);
  const coachesScrollRef = useRef(null);
  const partnershipScrollRef = useRef(null);
  const faqScrollRef = useRef(null);

  const executeScroll = (targetRef) => {
    console.log(targetRef.current);
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };
  
  const navigate = useNavigate();

  return (
    <div className={styles.page}>
      <Header
        onStepsPress={() => executeScroll(stepsScrollRef)}
        onCopilotsPress={() => executeScroll(copilotsScrollRef)}
        onCoachesPress={() => executeScroll(coachesScrollRef)}
        onPartnershipsPress={() => executeScroll(partnershipScrollRef)}
        onFaqPress={() => executeScroll(faqScrollRef)}
        onTryPress={() => {
          navigate("/sign-up");
          document.location.reload();
          // navigate(0);
        }}
      />
      <Hero scrollToRef={stepsScrollRef} />
      <CoPilots scrollToRef={copilotsScrollRef} />
      <Steps scrollToRef={stepsScrollRef} />
      <Coaches scrollToRef={coachesScrollRef} />
      <Lifestyle scrollToRef={partnershipScrollRef} />
      <Subscription
        className={styles.subscription}
        placeholder="Enter your email"
      />
      <Faq scrollToRef={faqScrollRef} />
      {/*<Quality />*/}
      {/*<Team />*/}
      <Footer />
    </div>
  );
};

export default Page;
