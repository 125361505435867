import React from 'react';
import cn from "classnames";
import styles from "./Hero.module.sass";
import Fade from '@material-ui/core/Fade';

const Hero = () => {
    return (
    <div className={cn("container", styles.container)}>
               <div> <Fade in={true} timeout={1250} style={{transitionDelay: '250ms'}}>
                <h1 className={cn("h1", styles.title)}>
                    Feel Good AI
                </h1></Fade>
        <Fade in={true} timeout={2500} style={{transitionDelay: '1250ms'}}>
        <h2 className={styles.text}>
                   We Find A Way   
        </h2></Fade></div>
            </div>
    );
};

export default Hero;
