import React from "react";
import cn from "classnames";
import styles from "./Post/Post.module.sass";
import ScrollParallax from "../../components/ScrollParallax";

const Lifestyle = ({ scrollToRef}) => {
    const items = [
        {
            title: "Partnership Opportunities",
            content:
                "Manage your clients and business through your dashboard and AI. Receive daily updates about the members in your care, and collaborate with Harmony to help manage all your members and business through AI.",
        },
        {
            title: "Health Insurance Providers",
            content:
                "We can work together to offer Feel Good AI as a value-added service to your members, promoting wellness and potentially reducing healthcare costs.",
        },
        {
            title: "Boutique Fitness Studios",
            content:
                "Integrate your unique fitness programs into our platform, reaching a wider audience and providing a new revenue stream.",
        },
        {
            title: "Enterprise Wellness Programs",
            content:
                "Enhance your corporate wellness program with our comprehensive and personalized AI co-pilots, promoting employee wellness and productivity.",
        },
        {
            title: "Non-Profit Organizations",
            content:
                "Collaborate with us to generate revenue for your mission through our Unity co-pilot, which donates a portion of its revenue to associated non-profits.",
        }
    ];

    return (
        // <div className={cn("section", styles.section)} ref={scrollToRef}>
            <div className={cn("container", styles.container)} ref={scrollToRef}>
                <div className={styles.head}>
                    <h1 className={styles.title}>
                        Partnerships with Feel Good AI
                    </h1>
                </div>
                <div className={styles.row}>
                    <div className={styles.list}>
                        {items.map((x, index) => (
                            <ScrollParallax className={styles.item} key={index}>
                                <div className={styles.category}>{x.title}</div>
                                <div className={styles.content}>{x.content}</div>
                            </ScrollParallax>
                        ))}
                    </div>
                </div>
            </div>
        // </div>
    );
};

export default Lifestyle;
