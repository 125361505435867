
import React from "react";
import cn from "classnames";
import styles from "./Coaches.module.sass";
import ScrollParallax from "../../components/ScrollParallax";

const Coaches = ({ scrollToRef }) => {
    const items = [
        {
            title: "Your Expertise, Our Technology",
            content:
                "Upload your programs to our platform and let our AI learn from your expertise. We'll mimic your coaching style and niche, creating personalized wellness plans for your clients. You'll also have the option to upload audio of your voice, allowing us to clone it and provide a more authentic coaching experience for your clients.",
        },
        {
            title: "Your Dashboard, Your Control",
            content:
                "Manage your clients and business through your dashboard and AI. You'll receive daily updates about the members in your care, and you'll be able to chat directly with them through the AI. This dashboard serves as your co-pilot, helping you manage all your members and business through voice interactions.",
        },
        {
            title: "You Own Your Data",
            content:
                "We respect your ownership of your data. The programs you upload are yours, and you can remove them at any time if you choose to leave the platform. We're here to coach on your behalf, not to take ownership of your work.",
        },
        {
            title: "Coming Soon: Voice Interactions",
            content:
                "We're working on a feature that will allow you to interact with your dashboard and manage your business through voice commands. This will make it even easier for you to stay on top of your clients' progress and manage your business.",
        },
        {
            title: "Join Us",
            content:
                "We're excited to have you on board. Together, we can revolutionize wellness coaching and help people live healthier, happier lives. Join us today and start making a bigger impact with your coaching.",
        },
    ];

    return (
            <div className={cn("container", styles.container)} ref={scrollToRef}>
                <div className={styles.head}>
                    <h1 className={styles.title}>
                        A Revolution in Wellness Coaching
                    </h1>
                </div>
                <div className={styles.row}>
                    <div className={styles.list}>
                        {items.map((x, index) => (
                            <ScrollParallax className={styles.item} key={index}>
                                <div className={styles.category}>{x.title}</div>
                                <div className={styles.content}>{x.content}</div>
                            </ScrollParallax>
                        ))}
                    </div>

                </div>
            </div>
    );
};

export default Coaches;