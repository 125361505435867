import React from 'react';
import styles from './Subscription.module.sass'; 
import Icon from '../Icon'; 
import MailchimpSubscribe from "react-mailchimp-subscribe";
const Subscription = () => {
    const MAILCHIMP_API = "//feelgoodai.us21.list-manage.com/subscribe/post?u=e75347d3ecefebb7000473d14&amp;id=7408bffa2f&amp;f_id=00c95fe1f0";

    return (
        <div className={styles.subscription}>
            <MailchimpSubscribe
                url={MAILCHIMP_API}
                render={({ subscribe, status, message }) => (
                    <CustomForm
                        status={status}
                        message={message}
                        onValidated={formData => subscribe(formData)}
                    />
                )}
            />
        </div>
    );
};
const CustomForm = ({ status, message, onValidated }) => {
    let email;
    const submit = () =>
        email &&
        email.value.indexOf("@") > -1 &&
        onValidated({
            EMAIL: email.value,
        });

    return (
<div>        <h1 className={styles.title}>Subscribe for updates!</h1>

    <div className={styles.form}>

        {status === "sending" && <div style={{ color: "blue" }}>sending...</div>}
            {status === "error" && (
                <div
                    style={{ color: "red" }}
                    dangerouslySetInnerHTML={{ __html: message }}
                />
            )}
            {status === "success" && (
                <div
                    style={{ color: "green" }}
                    dangerouslySetInnerHTML={{ __html: message }}
                />
            )}
            <input
                className={styles.input}
                ref={node => (email = node)}
                type="email"
                placeholder="Your email"
            />        
        <button className={styles.btn} onClick={submit}>
        <Icon name="arrow-right" size="14" />
        </button>
        </div>
</div>

    );
};

export default Subscription;