import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import cn from "classnames";
import styles from "./Footer.module.sass";
import Icon from "../Icon";

const menu = [
    {
        title: "Lifestyle",
        url: "/lifestyle",
    },
    {
        title: "Pricing",
        url: "/pricing",
    },
    {
        title: "Class",
        url: "/class01",
    },
    {
        title: "Features",
        url: "/features",
    },
    {
        title: "Download",
        url: "/download",
    },
];

const socials = [
    {
        title: "twitter",
        size: "18",
        url: "https://twitter.com/feelgoodai",
    },
    {
        title: "instagram",
        size: "16",
        url: "https://www.instagram.com/feelgoodai",
    }
];

const Footer = () => {
    return (
        <footer className={styles.footer}>
            <div className={styles.foot}>
                <div className={cn("container", styles.container)}>
                    <div className={styles.copyright}>
                        Copyright © 2023 Feel Good AI. All rights reserved.
                    </div>
                    <div className={styles.socials}>
                        {socials.map((x, index) => (
                            <a
                                className={styles.social}
                                href={x.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                key={index}
                            >
                                <Icon name={x.title} size={x.size} />
                            </a>
                        ))}
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
