import {useState} from "react";
import {Routes, Route} from "react-router-dom";
import "./styles/app.sass";
import Demo from "./screens/Demo";
import SignUp from "./screens/SignUp";
import Chat from "./screens/Chat";
import {initializeApp} from "firebase/app";
import {getAuth, onAuthStateChanged} from "firebase/auth";
import Athletes from "./screens/Athletes";
import Performers from "./screens/Performers";
import Page from "./components/Page";
import Dashboard from "./screens/Dashboard";

function App() {
    const firebaseConfig = {
        apiKey: "AIzaSyCEW7JJIzUoO82_-aGwuHQVbRmDsZ5-ME8",
        authDomain: "harmony-c543e.firebaseapp.com",
        projectId: "harmony-c543e",
        storageBucket: "harmony-c543e.appspot.com",
        messagingSenderId: "816967864736",
        appId: "1:816967864736:web:53ba2b819cde9dcb8754c5",
        measurementId: "G-Y2C62GBT5P",
    };
    const app = initializeApp(firebaseConfig);
    const [user, setUser] = useState(undefined);
    const [token, setToken] = useState(undefined);
    const auth = getAuth();

    onAuthStateChanged(auth, (user) => {
        if (user) {
            setUser(user);
            localStorage.setItem("userId", user.uid);
            user.getIdToken().then((result) => {
                setToken(result);
            });
        } else {
            setUser(undefined);
        }
    });

    const pages = ["Home", "About", "Services", "Contact", "Blog"];
    return (
        <Routes>
            <Route path="/">
                {/*<Route index element={<Page/>}/>*/}
                <Route index element={<Page />}/>
            </Route>
            <Route path="sign-up" element={<SignUp user={user} app={app}/>}/>
            <Route path="try-it-out" element={<Demo user={user}/>}/>
            <Route path="athletes" element={<Athletes user={user}/>}/>
            <Route path="performers" element={<Performers user={user}/>}/>
            <Route
                path="chat/:chatName"
                element={<Chat app={app} user={user} token={token}/>}
            />
        </Routes>
    );
}

export default App;
