import React, { useRef, useEffect } from 'react';
import cn from "classnames";
import ScrollParallax from "../../components/ScrollParallax";
import styles from "./Copilots.module.sass";

const CoPilots = ({ scrollToRef }) => {
  
  const videos = [
    {
      title: "Nourish",
      description: "Your Nutritional Co-Pilot for Health and Vitality",
      path: "/videos/Nourish-muted.mp4",
    },
    {
      title: "Stride",
      description: "Your Fitness Co-Pilot for Fitness and Longevity",
      path: "/videos/Stride-muted.mp4",
    },
    {
      title: "Reflect",
      description: "Your Mindfulness Co-Pilot for Focus and Fortitude",
      path: "/videos/Reflect-muted.mp4",
    },
    {
      title: "Enlighten",
      description: "Your Spiritual Co-Pilot and Companion",
      path: "/videos/Enlighten-muted.mp4",
    },
  ];

  return (
      <div className={cn("h1", styles.content)} ref={scrollToRef}>
        <div className={cn("container", styles.container)}>
          <ScrollParallax>
            <h1 className={cn("h1", styles.content)}>
          Personalized AI For Your Wellness Journey
            </h1>
          </ScrollParallax>
        </div>
        {videos.map((video, index) => (
            <div key={index} className={styles.videoContainer}>
              <VideoPlayer title={video.title} description={video.description} src={video.path} />
            </div>
        ))}
      </div>
  );
};

function VideoPlayer({ title, description, src }) {
  const videoRef = useRef(null);

  function AutoPlaySilentVideo(props) {
    const videoRef = useRef(undefined);
    useEffect(() => {
      videoRef.current.defaultMuted = true;
      const video = videoRef.current;

      const observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                video.play();
              } else {
                video.pause();
              }
            });
          },
          { threshold: window.innerWidth <= 768 ? 0.4 : 0.75 }
      );

      if (video) {
        observer.observe(video);
      }

      return () => {
        if (video) {
          observer.unobserve(video);
        }
      };

    })

    return (
    <video
            className={props.className}
            ref={videoRef}
            loop
            muted
            autoPlay
            playsInline
            preload="metadata"
        >
          <source src={props.video} type="video/mp4"/>
        </video>
    );
  }
  

  useEffect(() => {
    const video = videoRef.current;

    if (video) {
      observer.observe(video);
    }

    return () => {
      if (video) {
        observer.unobserve(video);
      }
    };
  }, []);

  return (
      <div>
        <div className={styles.content}>
          <ScrollParallax>
            <h3 className={styles.title}>{title}</h3>
            <p className={styles.info}>{description}</p>
          </ScrollParallax>
        </div>
        <AutoPlaySilentVideo
          className={styles.videoSlideshow}
          video={src}
          />
      </div>

);
}

export default CoPilots;
