import styles from "./Demo.module.sass";
import Image from "../../components/Image";
import {NavLink, useNavigate} from "react-router-dom";
import React from "react";
import {getAuth, signOut} from "firebase/auth";

const starters = [
    {
        title: "The Athlete’s Journey",
        text: "The journey begins with a deep exploration of the athlete's identity and understanding their unique archetype. By recognizing what motivates them, how they react under pressure, and what their primary goals and fears are, Harmony can tailor the support to their specific needs and personality. The athlete archetype serves as a foundation for all subsequent interactions, ensuring that the guidance is personalized and resonates with the individual's core self.",
        image: "../../images/square_orange.png"
    },
    {
        title: "The Life of a High Performer",
        text: "A profound exploration of the individual's identity, understanding their unique archetype as a high achiever. By recognizing what drives them, how they respond to challenges, and what their primary aspirations and concerns are, Harmony can tailor the support to their specific needs and personality. The high performer's archetype serves as a foundation for all subsequent interactions, ensuring that the guidance is personalized and resonates with the individual's core self. Whether they are a corporate leader, entrepreneur, artist, or anyone striving for excellence in their field, Harmony's nuanced approach helps them navigate the complexities of high performance, balancing ambition with well-being, and aligning their efforts with their true values and purpose.",
        image: "../../images/square_door.png"
    },
];

const athletes = [
    {
        title: "Identity and Performance Optimization for Active Athletes",
        text: "Building on the understanding of the athlete's identity, Harmony focuses on strategies to enhance both physical and mental performance. This includes personalized exercises, mental techniques, and tools to boost motivation, focus, and resilience. Whether it's preparing for a big competition or improving specific skills, Harmony offers expert guidance to help athletes reach their full potential.\n" +
            "Goals are the roadmap to success, and Harmony assists athletes in setting clear, achievable, and meaningful goals. By aligning goals with the athlete's identity and values, Harmony ensures that they are motivated and engaged in their pursuit. Regular progress tracking and adjustments keep the athlete on track and inspired.",
        image: "../../images/square_orange.png"
    },
    {
        title: "Injury Recovery and Identity Support for Athletes",
        text: "Injury recovery is not just a physical process; it's an emotional one. Harmony recognizes the frustration, fear, and uncertainty that often accompany injuries. By offering a compassionate and empathetic ear, Harmony helps athletes navigate these complex emotions, providing reassurance and encouragement.\n" +
            "Injuries can disrupt an athlete's routine, goals, and sense of self. Harmony offers tailored coping strategies to maintain motivation and positivity during this challenging time. Whether it's visualization techniques, mindfulness exercises, or personalized routines that align with the recovery process, Harmony ensures that athletes stay engaged and focused on their well-being.\n" +
            "By addressing the emotional, mental, and identity-related aspects of recovery, Harmony offers a holistic and personalized support system. Athletes are empowered to not only recover from their injury but to grow and thrive in the process.",
        image: "../../images/square_discs.png"
    },
    {
        title: "Transitioning Out of Sports - A Comprehensive Guide for Athletes",
        text: "The transition out of sports can be a complex and emotional journey for athletes. Harmony is designed to assist athletes as they navigate this significant life change, providing guidance on career options, personal branding, educational opportunities, and more. \n" +
            "Sports often play a central role in shaping an athlete's identity. Harmony helps athletes understand how their identity has been influenced by their sports career and how it might evolve as they transition into new roles and pursuits. This process includes self-reflection, exploration of values and interests, and the development of a new sense of self that aligns with their future goals.\n" +
            "Transitioning out of sports can bring feelings of loss, uncertainty, and anxiety. Harmony provides emotional support, offering empathy and understanding as athletes grapple with these emotions. Coping strategies, mindfulness exercises, and personalized guidance help athletes process their feelings and move forward with confidence and clarity.",
        image: "../../images/square_door.png"
    },
];

const performers = [
    {
        title: "Aligning Goals, Discovering Purpose, and Understanding Identity",
        text: "The journey begins by exploring the balance between being goal-oriented and process-oriented. Harmony helps High Performers recognize the value of both, ensuring that they are not merely on a hamster wheel but on a mission.\n" +
            "Building on goal orientation, Harmony uncovers intrinsic motivations and aligns efforts with a deeper sense of purpose. It assists in connecting with inner values and passions, ensuring that the pursuit of legacy is meaningful and fulfilling.\n" +
            "Harmony delves into identity beyond transient labels like job, relationships, or education. It assists in navigating changes and growing through uncertainty, empowering High Performers to maintain an authentic sense of self.",
        image: "../../images/square_orange.png"
    },
    {
        title: "Applying Wisdom, Enduring Suffering, and Embracing Legacy",
        text: "Life is finite, and Harmony encourages High Performers to apply wisdom to everyday life and surround themselves with people who add value, push, and refine them. It prompts reflection on whether they are living in alignment with their true selves and values.\n" +
            "Harmony addresses the endurance of suffering and the striving for legacy. It helps High Performers recognize that the days may be long, but the years are short. It guides them in finding meaning in suffering and aligning their pursuits with a lasting and impactful legacy.\n" +
            "This chat delves into the deeper existential aspects of being a High Performer, including the application of wisdom, the endurance of suffering, and the embrace of legacy. It guides High Performers in living a life of purpose, wisdom, and impact, and helps them navigate the complex challenges of life with grace and resilience.",
        image: "../../images/square_discs.png"
    },
    {
        title: "Cultivating Mindsets and Leadership Excellence",
        text: "A comprehensive exploration of various mindsets that are essential for High Performers, especially those in leadership roles. From curiosity to moonshot thinking, Harmony provides the tools and guidance to cultivate these mindsets and apply them to personal and professional life. It empowers High Performers to lead with excellence, inspire others, and create a lasting impact.\n" +
            "\n" +
            "Leadership Excellence: Building on these mindsets, Harmony guides High Performers in applying them to leadership. It focuses on empathy, vision, communication, and collaboration, helping them become inspiring and effective leaders.",
        image: "../../images/square_door.png"
    },
];


const Demo = () => {
    const navigate = useNavigate();
    const auth = getAuth();

    const starterButtons = starters.map(({title, text, image}, index) => (
        <div key={index}>
            <div className={styles.title}>
                <h4>{title}</h4>
            </div>
            <div className={styles.itemContainer}>
                <Image
                    className={styles.thumbnail}
                    src={image}
                    srcDark={image}
                    alt={title}
                />
                <div className={styles.contentContainer}>
                    <div className={styles.text} style={{textShadow: '2px 2px 6px #777E90' }}>{text}</div>
                    <button
                        onClick={() => {
                            if (index === 0) {
                                navigate("/athletes")
                            } else if (index === 1) {
                                navigate("/performers")
                            }
                        }}
                        className={styles.button}
                    >
                        {"Start"}
                    </button>
                </div>
            </div>
        </div>
    ));

    return (
        <div className={styles.fullPage}>
            <div className={styles.navbar}>
                <div style={{color: '#1c1c1c'}}>
                    {auth?.currentUser?.email}
                    <span style={{marginLeft: `50px`}}/>
                    {/*<NavLink className={styles.link} to="/try-it-out">*/}
                    {/*  Menu*/}
                    {/*</NavLink>*/}
                </div>
                <div>
                    <NavLink
                        className={styles.link}
                        onClick={() => {
                            signOut(auth)
                                .then(() => {
                                    localStorage.setItem("userId", undefined);
                                    navigate("/");
                                })
                                .catch(() => {
                                    localStorage.setItem("userId", undefined);
                                    navigate("/");
                                    console.error("something went wrong");
                                });
                        }}
                    >
                        Log out
                    </NavLink>
                </div>
            </div>
            <div
                className={styles.logoContainer}
            >
                <Image
                    className={styles.logo}
                    src="../../images/logo-dark-cropped.svg"
                    srcDark="../../images/logo-dark-cropped.svg"
                    alt="Feel Good AI"
                />
            </div>
                <div className={styles.headerContainer}>
                    <div>
                        <h1>Begin Your Journey</h1>
                    </div>

                </div>
            <div className={styles.promptSelection}>
                {starterButtons}
            </div>
        </div>
    );
};

export default Demo;
