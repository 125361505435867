import React, { useState } from "react";
import cn from "classnames";
import styles from "./Faq.module.sass";
import Item from "./Item";
import Dropdown from "../../../components/Dropdown";

const items = [
  {
    title: "General",
    items: [
      {
        title: "What is Feel Good AI?",
        answer: "Feel Good AI is a wellness platform that offers four AI co-pilots: Stride, Nourish, Reflect, and Enlighten. Each co-pilot is designed to help you in different aspects of your wellness journey.",
      },
      {
        title: "How does Feel Good AI contribute to non-profit organizations?",
        answer: "For every subscription, we donate $5 from individual memberships and partner unlimited memberships, and $10 from non-partner unlimited memberships to non-profit organizations associated with our Unity co-pilot.",
      },
      {
        title: "How does Feel Good AI handle my data?",
        answer: "We prioritize your privacy. We only use your data to enhance your experience and improve our services. We do not sell your data to third parties.",
      },
      {
        title: "Can I use Feel Good AI if I'm not tech-savvy?",
        answer: "Absolutely! Feel Good AI is designed to be user-friendly. Our co-pilots guide you through the process, making it easy for anyone to use.",
      },
      {
        title: "How does Feel Good AI support its users?",
        answer: "We provide comprehensive support to our users. We have a dedicated customer service team ready to assist you with any queries or issues you may encounter.",
      },
      {
        title: "How does Feel Good AI ensure the quality of its services?",
        answer: "We use advanced AI technology to provide personalized wellness guidance. Our co-pilots are designed to adapt to your unique needs and preferences.",
      },
    ],
  },
  {
    title: "Co-pilot",
    items: [
      {
        title: "What is the Stride co-pilot?",
        answer: "Stride is your personal fitness co-pilot. It guides you through your workouts, tracks your progress, and provides real-time feedback to help you achieve your fitness goals.",
      },
      {
        title: "What is the Nourish co-pilot?",
        answer: "Nourish is your personal nutrition co-pilot. It provides personalized meal plans, tracks your nutrition intake, and offers guidance to help you maintain a balanced diet.",
      },
      {
        title: "What is the Reflect co-pilot?",
        answer: "Reflect is your personal mental wellness co-pilot. It offers mindfulness exercises, stress management techniques, and mental wellness resources to help you achieve mental balance and peace.",
      },
      {
        title: "What is the Enlighten co-pilot?",
        answer: "Enlighten is your personal spiritual growth co-pilot. It provides access to a wide range of spiritual content, including sermons and faith-based podcasts.",
      },
    ],
  },
  {
    title: "Subscription",
    items: [
      {
        title: "How much does a Feel Good AI subscription cost?",
        answer: "You can subscribe to any individual co-pilot (Harmony or Unity) for $15 per month. For access to all four co-pilots, the cost is $30 per month. For partnership members, the all-access subscription is available at a discounted rate of $15 per month.",
      },
      {
        title: "What does the subscription include?",
        answer: "The subscription gives you full access to the co-pilot(s) you've subscribed to. You'll be able to utilize all the features and services offered by the co-pilot(s).",
      },
      {
        title: "How does the partnership membership work?",
        answer: "Partnership membership is available to members of health insurance companies, gyms, or any other partner of Feel Good AI. These members can access all four co-pilots for a discounted rate of $15 per month.",
      },
      {
        title: "Can I cancel my subscription at any time?",
        answer: "Yes, you can cancel your subscription at any time. There are no cancellation fees. However, please note that we do not offer refunds for the current billing period.",
      },
    ],
  },
  {
    title: "Coaches",
    items: [
      {
        title: "What is the role of human coaches in Feel Good AI?",
        answer: "Human coaches play a crucial role in Feel Good AI. They provide the expertise and personal touch that our AI co-pilots draw from to create personalized wellness plans. The AI acts on behalf of these coaches, allowing them to manage and interact with all their members through the platform.",
      },
      {
        title: "How can I become a coach on Feel Good AI?",
        answer: "We are always looking for experienced wellness coaches to join our platform. If you are interested, please visit our Coaches page for more information and to apply.",
      },
      {
        title: "Do coaches have to be certified or have specific qualifications to join Feel Good AI?",
        answer: "Yes, we require all coaches on our platform to have relevant certifications and qualifications in their respective fields. This ensures that our users receive expert guidance and support.",
      },
      {
        title: "How are coaches compensated on Feel Good AI?",
        answer: "Coaches on Feel Good AI are compensated based on the number of users they support and the plans that these users subscribe to. More details can be found in our Coaches Agreement.",
      },
      {
        title: "Can coaches interact directly with users on Feel Good AI?",
        answer: "Yes, coaches can interact directly with their users through the platform. Our AI acts on behalf of the coaches, allowing them to manage and chat with all their members.",
      },
    ],
  },
];

const Team = ({ scrollToRef }) => {
  const options = [];
  items.map((x) => options.push(x.title));

  const [category, setCategory] = useState(options[0]);

  return (
    <div className={cn("section-border-top", styles.section) } ref={scrollToRef}>
      <div className={cn("container", styles.container)}>
        <div className={styles.top}>
          <div className={cn("stage-small", styles.stage)}>
            Feel Good AI
          </div>
          <h2 className={cn("h2", styles.title)}>Frequently Asked Questions</h2>
          <div className={styles.info}>
            For other questions contact <a >info@feelgoodai.com</a>
          </div>
          <div className={styles.nav}>
            {items.map((x, index) => (
              <button
                className={cn(styles.btn, {
                  [styles.active]: x.title === category,
                })}
                onClick={() => setCategory(x.title)}
                key={index}
              >
                {x.title}
              </button>
            ))}
          </div>
          <Dropdown
            className={styles.dropdown}
            value={category}
            setValue={setCategory}
            options={options}
          />
        </div>
        <div className={styles.list}>
          {items
            .find((x) => x.title === category)
            .items.map((x, index) => (
              <Item item={x} key={index} />
            ))}
        </div>
      </div>
    </div>
  );
};

export default Team;
